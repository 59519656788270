import React from 'react';
import PropTypes from 'prop-types';
import AGGrid from '../../../Common/AGGrid';
import AGGridCommonHeader from '../../../Common/AGGridCommonHeader';
import commonConst from '../../../../constants/commonConst';
import { toUIDateTimeStr } from '../../../../utils/datetimeHelper';
import { formatTwoDecimal, formatZeroDecimal } from '../../../../utils/decimalHelper';
import { formatNullDisplayStrWithDash } from '../../../../utils/stringHelper';

const EventUptimeMonitoringEventDetailDatatable = (props) => {
    const { data, showPagination, showTable, totalRows, currentPage, rowsPerPage, handlePageChange, handleRowsPerPageChange } = props;
    const columns = [
        {
            headerName: 'No.',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'No.',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word  ${row.data.eventName === commonConst.TOTAL ? 'font-weight-bold' : ''}`}>
                        {row.data.eventName !== commonConst.TOTAL ? row.data.rowNo : commonConst.TOTAL}
                    </div>
                </div>
            ),
            minWidth: 40,
            maxWidth: 60,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Sport',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Sport',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word`}>{row.data.eventName !== commonConst.TOTAL ? row.data.sportName : ''}</div>
                </div>
            ),
            minWidth: 120,
            maxWidth: 180,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Event Date',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Event Date',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word`}>{row.data.eventName !== commonConst.TOTAL ? toUIDateTimeStr(row.data.eventDate) : ''}</div>
                </div>
            ),
            minWidth: 180,
            maxWidth: 250,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Competition',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Competition',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word`}>{row.data.eventName !== commonConst.TOTAL ? row.data.competitionName : ''}</div>
                </div>
            ),
            minWidth: 250,
        },
        {
            headerName: 'Event',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Event',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word`}>{row.data.eventName !== commonConst.TOTAL ? row.data.eventName : ''}</div>
                </div>
            ),
            minWidth: 300,
        },
        {
            headerName: 'No. of Wager',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'No. of Wager',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word text-right ${row.data.eventName === commonConst.TOTAL ? 'font-weight-bold' : ''}`}>
                        {formatNullDisplayStrWithDash(formatZeroDecimal(row.data.wagerCount, commonConst.DASH), commonConst.DASH)}
                    </div>
                </div>
            ),
            minWidth: 120,
            maxWidth: 180,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Total Member Exposure',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Total Member Exposure',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word text-right ${row.data.eventName === commonConst.TOTAL ? 'font-weight-bold' : ''} `}>
                        {formatNullDisplayStrWithDash(formatZeroDecimal(row.data.exposureAmount, commonConst.DASH), commonConst.DASH)}
                    </div>
                </div>
            ),
            minWidth: 120,
            maxWidth: 180,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Wager Win/Loss',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Wager Win/Loss',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div
                        className={`cg-col-item-left prewrap break-word text-right ${row.data.eventName === commonConst.TOTAL ? 'font-weight-bold' : ''} ${
                            row.data.winLossAmount >= 0 ? '' : 'text-danger'
                        }`}
                    >
                        {formatNullDisplayStrWithDash(formatTwoDecimal(row.data.winLossAmount, commonConst.DASH), commonConst.DASH)}
                    </div>
                </div>
            ),
            minWidth: 120,
            maxWidth: 180,
            suppressSizeToFit: true,
        },
        {
            headerName: 'Wager Win/Loss %',
            headerComponent: AGGridCommonHeader,
            headerComponentParams: {
                value: 'Wager Win/Loss %',
            },
            cellRenderer: (row) => (
                <div className="flex_vertical">
                    <div className={`cg-col-item-left prewrap break-word text-right ${row.data.eventName === commonConst.TOTAL ? 'font-weight-bold' : ''}`}>
                        {formatNullDisplayStrWithDash(formatTwoDecimal(row.data.winLossAmountPercentage, commonConst.DASH), commonConst.DASH)}
                    </div>
                </div>
            ),
            minWidth: 120,
            maxWidth: 180,
            suppressSizeToFit: true,
        },
    ];
    return (
        <>
            <AGGrid
                columnDefs={columns}
                showPagination={showPagination}
                rowData={data}
                showTable={showTable}
                totalRows={totalRows}
                currentPage={currentPage}
                rowsPerPage={rowsPerPage}
                handlePageChange={handlePageChange}
                handlePerRowsChange={handleRowsPerPageChange}
                rowHeight={commonConst.AGGRID_ROWHEIGHTSIZE}
            />
        </>
    );
};

EventUptimeMonitoringEventDetailDatatable.propTypes = {
    data: PropTypes.array,
    showPagination: PropTypes.bool,
    showTable: PropTypes.bool,
    totalRows: PropTypes.number,
    currentPage: PropTypes.number,
    rowsPerPage: PropTypes.number,
    handlePageChange: PropTypes.func,
    handleRowsPerPageChange: PropTypes.func,
};

export default EventUptimeMonitoringEventDetailDatatable;
