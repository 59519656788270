import { useRef, useEffect, useMemo } from 'react';
import debounce from 'lodash.debounce';

const useDebounce = (callback, interval = 1500, maxWait = null, invokeFirst = false) => {
    const ref = useRef();

    useEffect(() => {
        ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
        const func = (...args) => {
            ref.current?.(...args);
        };

        return debounce(func, interval, { leading: invokeFirst, trailing: !invokeFirst, maxWait: maxWait }); // debounce interval
    }, []);

    return debouncedCallback;
};

export default useDebounce;
