import React from 'react';
import PropTypes from 'prop-types';

const AGGridCommonHeader = (props) => {
    return (
        <>
            <div className="row_wrap text-wrap">{props.value}</div>
        </>
    );
};

AGGridCommonHeader.propTypes = {
    value: PropTypes.string.isRequired,
};

export default AGGridCommonHeader;
