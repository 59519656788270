exports.formatNullToZero = (value) => {
    try {
        if (value === undefined || value === null || value === '') return 0;
    } catch (e) {
        console.log(e);
    }
    return value;
};

exports.formatTwoDecimal = (value, nullStr = 'N/A') => {
    try {
        if (value !== undefined && value !== null && value !== '') return (parseInt(value * 100) / 100).toFixed(2);

        return nullStr;
    } catch (e) {
        console.log(e);
        return value;
    }
};

exports.formatZeroDecimal = (value) => {
    if (value !== undefined && value !== null && value !== '') return Math.trunc(value);
    else return '';
};

exports.formatThousandSeparator = (str) => {
    if (str !== undefined && str !== null && str !== '') return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

exports.formatSpecialValue = (value, specialStr = '-1', defaultDisplayStr = 'N/A') => {
    if (value !== undefined && value !== null && value !== '') return Math.trunc(value) === specialStr ? defaultDisplayStr : value;

    return defaultDisplayStr;
};

exports.safeDivide = (numerator, denominator) => {
    if (denominator !== null && denominator !== 0) return numerator / denominator;
    return null;
};

exports.isNumber = (value) => {
    return typeof value === 'number' && Number.isFinite(value);
};

exports.formatNDecimal = (value, decimalPrecision, nullStr = 'N/A') => {
    try {
        if (value !== undefined && value !== null && value !== '')
            return (parseInt(value * Math.pow(10, decimalPrecision)) / Math.pow(10, decimalPrecision)).toFixed(decimalPrecision);

        return nullStr;
    } catch (e) {
        console.log(e);
        return value;
    }
};
