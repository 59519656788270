module.exports = {
    SETTING_USER: 'User Listing',
    MEMBERSETTING_MEMBERDASHBOARD: 'Member Dashboard',
    MEMBERSETTING_MEMBERDASHBOARD_GBFEATURES: 'GB Features',
    MEMBERSETTING_MEMBERDASHBOARD_LAST3REMARKS: 'Last 3 Remarks',
    MEMBERSETTING_MEMBERDASHBOARD_DAILYPERFORMANCE: 'Daily Performance',
    MEMBERSETTING_MEMBERDASHBOARD_MONTHLYPERFORMANCE: 'Monthly Performance',
    MEMBERSETTING_MEMBERDASHBOARD_SPORTSPERFORMANCE: 'Sports Performance',
    MEMBERSETTING_MEMBERDASHBOARD_COMBOPERFORMANCE: 'Combo Performance',
    MEMBERSETTING_MEMBERDASHBOARD_TIERPERFORMANCE: 'Tier Performance - Single Wager Only',
    MEMBERSETTING_MEMBERDASHBOARD_WAGERSOURCEPERFORMANCE: 'Wager Source Performance',
    MEMBERSETTING_MEMBERDASHBOARD_MARKETTYPEPERFORMANCE: 'Market Type Performance',
    MEMBERSETTING_MEMBERDASHBOARD_COMPETITIONPERFORMANCE: 'Competition Performance - Single Wager Only',
    MEMBERSETTING_MEMBERDASHBOARD_BETTYPEPERFORMANCE: 'Bet Type Performance',
    MEMBERSETTING_MEMBERDASHBOARD_CANCELLEDWAGER: 'Cancelled Wager',
    MEMBERSETTING_MEMBERDASHBOARD_MEMBERWINLOSSDIFFERENT: 'Member WinLoss Different',
    MEMBERSETTING_MEMBERDASHBOARD_SYSTEMREJECTION: 'System Rejection',
    MEMBERSETTING_MEMBERDASHBOARD_SCOREANDACTION: 'Score And Action',
    MEMBERSETTING_MEMBERDASHBOARD_STAKERANGE: 'Stake Range',
    MEMBERSETTING_MEMBERDASHBOARD_IPADDRESS: 'IP Address',
    MEMBERSETTING_MEMBERDASHBOARD_OVERALLPERFORMANCE: 'Overall Performance',
    MEMBERSETTING_MEMBERDASHBOARD_BETTOGETHER: 'Bet Together',
    MEMBERSETTING_PROFILEGROUP: 'Profile Group',
    MEMBERSETTING_SCOREPROFILE: 'Score Profile',
    MEMBERSETTING_MEMBERCATEGORYSETTINGS: 'Member Category Settings',
    MEMBERSETTING_MEMBERDASHBOARD_WAGERMEMBERCATEGORYPERFORMANCE: 'Wager Member Category Performance',
    MEMBERSETTING_MEMBERPROFILINGASSIGNMENT: 'Member Profiling Assignment',

    SYSTEMSETTING_GENERALSETTINGS: 'General Settings',

    SUPPORT_MALOFT_DAILYELT: 'Maloft Daily ETL',
    SUPPORT_DATAEXTRACTION: 'Daily Data Extraction',

    WAGERREPORT_ARBERBETREPORT: 'Arber Bet Report',
    RISKMANAGEMENT_BETATTEMPTREPORT: 'Bet Attempt Report',
    DATARECON_EVENTRECONREPORT: 'Event Reconciliation Report',
    DATARECON_AGBMEMBERSUMMARYRECONREPORT: 'Late Bet - Member Summary',

    NOTIFICATION: 'Notifications',

    MONITORING_EVENTUPTIME: 'Event Uptime Monitoring',
    MONITORING_EVENTUPTIME_EVENTDETAILS: 'Event Uptime Monitoring By Operator > Event Detail',
};
