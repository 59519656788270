import React from 'react';

const CommonNoRecordsDisplay = () => {
    return (
        <>
            <div className="empty_container">
                <div className="empty_label">There are no records to display</div>
            </div>
        </>
    );
};

export default CommonNoRecordsDisplay;
