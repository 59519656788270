import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apis from '../../apis';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import { loadFromLocalStorage } from '../../utils/storageHelper';
import storageConst from '../../constants/storage';
import CommonNoRecordsDisplay from '../Common/CommonNoRecordsDisplay';
import Pagination from '../Common/TablePagination';
import useDebounce from './useDebounce';

const AGGrid = (props) => {
    const [gridApi, setGridApi] = useState(null);
    const [currentUser] = useState(loadFromLocalStorage(storageConst.CURRENT_USER));
    const [columnDefFromAPI, setColumnDefFromAPI] = useState(null);
    const isDayRangeChanged = props.isDayRangeChanged;

    useEffect(() => {
        if (isDayRangeChanged === true) {
            getColumnDefs();
        }
        if (gridApi) {
            getColumnDefs();
            gridApi.setDomLayout(null);
            gridApi.setRowData(props.rowData);
        }
    }, [gridApi, isDayRangeChanged]);

    useEffect(() => {
        //to refetch searchCriteria when columnDef changed.
        getColumnDefs();
    }, [props.columnDefs]);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const defaultColDef = {
        sortable: false,
        icons: {
            sortAscending: '<i class="fa fa-sort-asc"/>',
            sortDescending: '<i class="fa fa-sort-desc"/>',
        },
    };

    const gridOptions = {
        getRowStyle: (params) => {
            if (props.isLastRowStyled && params.node.rowIndex === params.api.getDisplayedRowCount() - 1) {
                return { fontWeight: 'bold' };
            }
            return null;
        },
        postSortRows: (rowNodes) => {
            if (props.isLastRowStyled)
                for (let y in rowNodes.nodes) {
                    let x = parseInt(y);
                    if (rowNodes.nodes[x]?.rowIndex !== null) rowNodes.nodes[x]?.rowIndex === rowNodes.nodes.length - 1 && rowNodes.nodes.push(rowNodes.nodes.splice(x, 1)[0]);
                }
        },
    };

    const onFirstDataRendered = () => {
        setTimeout(() => {
            gridApi.setDomLayout('autoHeight');
        }, 500);
        window.dispatchEvent(new Event('resize'));
    };

    window.addEventListener(
        'resize',
        useDebounce(
            function () {
                setTimeout(() => {
                    if (gridApi !== null) {
                        if (!gridApi.destroyCalled) {
                            getColumnDefs();
                            gridApi.sizeColumnsToFit();
                        }
                    }
                }, 100);
            },
            100,
            300,
            false,
        ),
    );

    const onColumnMoved = useDebounce(async () => {
        if (!props.module) return; // skip when module is null/undefined
        let savedColumnDefs = [];
        let criterias = [];
        if (gridApi != null) {
            gridApi.getColumnDefs().forEach(function (colDef) {
                savedColumnDefs.push({
                    headerName: colDef.headerName === undefined ? colDef.headerComponent.name + colDef.headerComponentParams?.dayRangeName : colDef.headerName,
                });
            });
            setColumnDefFromAPI(savedColumnDefs);
            criterias.push({
                filterCriteria: 'gridColumnOrder',
                filterValue: JSON.stringify(savedColumnDefs),
            });
            await apis.saveSearchCriterias({
                module: props.module,
                userName: currentUser.name,
                criterias: criterias,
            });
        }
    });

    const getColumnDefs = useDebounce(async () => {
        if (!props.module) {
            if (gridApi) {
                gridApi.setColumnDefs(props.columnDefs);
            }
            return; // skip when module is null/undefined
        }

        let savedColumnDefs = [];
        let newColumnDefs = [];
        let columnDefs = [];

        if (gridApi != null) {
            if (!columnDefFromAPI) {
                const criteria = await apis.getSearchCriterias({ module: props.module, userName: currentUser.name });

                if (criteria.data.length > 0) {
                    columnDefs = JSON.parse(criteria.data[0].filterValue);
                }
            } else {
                columnDefs = columnDefFromAPI.map((x) => ({
                    headerName: x.headerName,
                }));
            }

            let columnDefsProps = props.columnDefs.sort((a, b) => {
                let aPos = columnDefs.map((x) => x.headerName).indexOf(a.headerName);
                let bPos = columnDefs.map((x) => x.headerName).indexOf(b.headerName);
                return (aPos === -1 ? Number.MAX_SAFE_INTEGER : aPos) - (bPos === -1 ? Number.MAX_SAFE_INTEGER : bPos);
            });
            if (columnDefs.length > 0) {
                columnDefsProps.forEach(function (col) {
                    if (columnDefs.filter((x) => x.headerName === col.headerName).length > 0) savedColumnDefs.push(col);
                    else if (
                        col.headerComponent !== null &&
                        col.headerComponent !== undefined &&
                        columnDefs.filter((x) => x.headerName === col.headerComponent.name + (col.headerComponentParams?.dayRangeName || '')).length > 0
                    ) {
                        savedColumnDefs.push(col);
                    } else newColumnDefs.push(col);
                });
                savedColumnDefs = savedColumnDefs.concat(newColumnDefs);
            } else savedColumnDefs = props.columnDefs;
            if (props.module === 12) {
                //special handle for MBC day range filter if previous saved searchcriteria is missing new search's checked day ranges
                if (savedColumnDefs.length < props.columnDefs.length) {
                    const missingRange = props.columnDefs.filter(({ headerComponentParams: id1 }) => !savedColumnDefs.some(({ headerComponentParams: id2 }) => id2 === id1));

                    missingRange.forEach(function (colHeader) {
                        savedColumnDefs.push(colHeader);
                    });

                    //rearrange the day range in ascending order
                    if (props.module === 12) {
                        savedColumnDefs.filter((x) => x.headerName === undefined).sort((a, b) => a.headerComponentParams.dateFrom - b.headerComponentParams.dateFrom);
                    }

                    savedColumnDefs = savedColumnDefs
                        .filter((x) => x.headerName !== undefined)
                        .concat(
                            props.module === 12
                                ? savedColumnDefs
                                      .filter((x) => x.headerName === undefined)
                                      .sort((a, b) => (a.headerComponentParams.dateFrom > b.headerComponentParams.dateFrom ? -1 : 1))
                                : savedColumnDefs.filter((x) => x.headerName !== undefined).concat(savedColumnDefs.filter((x) => x.headerName === undefined)),
                        );
                }
            }

            if (props.module === 19) {
                //special handle for MBC day range filter if previous saved searchcriteria is missing new search's checked day ranges
                if (savedColumnDefs.length < props.columnDefs.length) {
                    const missingRange = props.columnDefs.filter(({ headerComponentParams: id1 }) => !savedColumnDefs.some(({ headerComponentParams: id2 }) => id2 === id1));

                    missingRange.forEach(function (colHeader) {
                        savedColumnDefs.push(colHeader);
                    });

                    //rearrange the day range in ascending order
                    savedColumnDefs.filter((x) => x.headerName === undefined).sort((a, b) => a.headerComponentParams.dateFrom - b.headerComponentParams.dateFrom);
                    savedColumnDefs = savedColumnDefs.filter((x) => x.headerName !== undefined).concat(savedColumnDefs.filter((x) => x.headerName === undefined));
                }
            }

            if (props.module === 61 || props.module === 79) {
                //special handle for MLTagging (Wager & UI) day range column to rearrange in ascending order
                const overallToLatestColumn = savedColumnDefs.filter((x) => x.headerName.includes('Overall to Latest'))[0];
                const afterLatestColumn = savedColumnDefs.filter((x) => x.headerName.includes('After Latest'))[0];
                const withoutDayRangeColumns = savedColumnDefs.filter((x) => !x.headerName.includes('After Latest') && !x.headerName.includes('Overall to Latest'));

                if (afterLatestColumn !== undefined && overallToLatestColumn !== undefined) {
                    withoutDayRangeColumns.push(overallToLatestColumn);
                    withoutDayRangeColumns.push(afterLatestColumn);
                    savedColumnDefs = withoutDayRangeColumns;
                }
            }

            setColumnDefFromAPI(savedColumnDefs);
            if (!gridApi.destroyCalled) {
                gridApi.setColumnDefs(savedColumnDefs);
                gridApi.sizeColumnsToFit();
            }
        }
    }, 300);

    const onGridSizeChanged = useDebounce((params) => {
        params.api.showLoadingOverlay();
        setTimeout(() => {
            params.api.sizeColumnsToFit();
            params.api.hideOverlay();
        }, 150);
    }, 500);

    return (
        <>
            {props.rowData.length > 0 ? (
                <>
                    <Pagination
                        count={props.totalRows}
                        page={props.currentPage}
                        rowsPerPage={props.rowsPerPage}
                        onChangePage={props.handlePageChange}
                        onChangeRowsPerPage={props.handlePerRowsChange}
                        className={props.showTable && props.rowData.length > 0 && (props.showPagination === undefined ? true : props.showPagination) ? '' : 'd-none'}
                    />
                    <div className={`ag-theme-alpine des-theme ${props.isCustomMergedHeader ? `custom-merged-header` : ``}`}>
                        <AgGridReact
                            rowData={props.rowData}
                            rowHeight={props.rowHeight ?? null}
                            onGridReady={onGridReady}
                            onFirstDataRendered={onFirstDataRendered}
                            animateRows={true}
                            suppressCellFocus={true}
                            enableCellTextSelection={true}
                            suppressDragLeaveHidesColumns={true}
                            suppressChangeDetection={true}
                            tooltipShowDelay={0}
                            onColumnMoved={onColumnMoved}
                            onGridSizeChanged={onGridSizeChanged}
                            overlayLoadingTemplate={
                                '<div className="animated--fade-in pt-1 text-center"><div className="spinner-border text-primary text-center" role="status"><span className="sr-only">Loading...</span></div></div><div className="animated--fade-in pt-1 text-center">Loading...</div>'
                            }
                            gridOptions={gridOptions}
                            tooltipMouseTrack={true}
                            defaultColDef={{ defaultColDef, ...props.defaultColDef }}
                            popupParent={document.querySelector('body')}
                            getRowHeight={props?.getRowHeight}
                        />
                    </div>
                    <Pagination
                        count={props.totalRows}
                        page={props.currentPage}
                        rowsPerPage={props.rowsPerPage}
                        onChangePage={props.handlePageChange}
                        onChangeRowsPerPage={props.handlePerRowsChange}
                        className={props.showTable && props.rowData.length > 0 && (props.showPagination === undefined ? true : props.showPagination) ? '' : 'd-none'}
                    />
                </>
            ) : props.showTable ? (
                <CommonNoRecordsDisplay />
            ) : (
                ''
            )}
        </>
    );
};

AGGrid.propTypes = {
    columnDefs: PropTypes.array,
    rowData: PropTypes.array,
    module: PropTypes.number,
    totalRows: PropTypes.number,
    currentPage: PropTypes.number,
    rowsPerPage: PropTypes.number,
    showTable: PropTypes.bool,
    showPagination: PropTypes.bool,
    data: PropTypes.object,
    handlePerRowsChange: PropTypes.func,
    handlePageChange: PropTypes.func,
    isCustomMergedHeader: PropTypes.bool,
    isDayRangeChanged: PropTypes.bool,
    setIsDayRangeChanged: PropTypes.object,
    defaultColDef: PropTypes.object,
    rowHeight: PropTypes.number,
    tooltipMouseTrack: PropTypes.bool,
    isLastRowStyled: PropTypes.bool,
    getRowHeight: PropTypes.func,
};

export default AGGrid;
