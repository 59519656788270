module.exports = {
    FORBIDDEN_ACCESS: 'Forbidden Access!',
    INVALID_COMPANY_NAME: 'Invalid Company Name.',

    //#region Common
    ERROR_FETCHINGDATA: 'Error on fetching data!',
    //#endregion

    //#region Profile Group
    SUCCESS_UPDATEPROFILEGROUPPRIORITY: 'Edit Profile Group Priority successful.',
    ERROR_PROFILEGROUP_GETLIST: 'Error fetching Profile Group list!',
    ERROR_PROFILEGROUPPRIORITY_GETLIST: 'Error fetching Profile Group Priority list!',
    ERROR_PROFILEGROUPPRIORITY_UPDATE: 'Error on updating Profile Group Priority: ',
    ERROR_SAVEINACTIVEPRIORITY: 'Inactive Priority cannot be saved.',
    //#endregion

    //#region Score Profile
    ERROR_SCOREPROFILE_GETLIST: 'Error fetching Score Profile list!',
    ERROR_SCOREPROFILE_ADDEDIT: 'Failed to save Score Profile!',
    //#endregion

    //#region
    ERROR_MEMBERCATEGORYSETTING_GETLIST: 'Error on fetching Member Category Setting List!',
    ERROR_MEMBERCATEGORYSETTING_ADDEDIT: 'Failed to save Member Category Setting',
    //#endregion

    //#region
    ERROR_MEMBERPROFILINGASSIGNMENT_GETLIST: 'Error on fetching Member Profiling Assignment List!',
    ERROR_MEMBERPROFILINGASSIGNMENT_ADDEDIT: 'Failed to save Member Profiling Assignment',
    ERROR_MEMBERPROFILINGASSIGNMENT_EXPORT: 'Error occurred while creating an offline export in Member Profiling Assignment.',
    //#endregion

    //#region
    ERROR_ROLE_GETLIST: 'Error on fetching Role List!',
    ERROR_ROLE_ADDEDIT: 'Failed to save Role',
    //#endregion

    //#region Member Profiling Assignment
    PENDING_MEMBERPROFILINGASSIGNMENT_UPDATE: 'Please stay on this page while we process your request.',
    ERROR_TIMEOUT_MEMBERPROFILINGASSIGNMENT_UPDATE: 'Timeout exceed 5mins waiting, please check again from member listing.',
    SUCCESS_UPDATEMEMBERPROFILINGASSIGNMENT: 'Member successfully checked.',
    ERROR_MEMBERPROFILINGASSIGNMENT_UPDATEACTION: 'Processing failed. Please manually trigger from Member Listing.',
    //#endregion
};
