import { saveToLocalStorage } from '../../utils/storageHelper';
import storageConst from '../../constants/storage';
const handleError = async (error, history) => {
    if (error === 403) {
        saveToLocalStorage(storageConst.AUTH_ERROR, true);
        history.push('../login');
    }
    return;
};
export default handleError;
