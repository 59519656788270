import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { authHelper } from '../../../../utils/authHelper';
import permissionConst from '../../../../constants/permissionConst';
import messageConst from '../../../../constants/messageConst';
import moduleConst from '../../../../constants/moduleConst';
import { getAdvanceDateByDay, toDateTimeStr, toDateTimeUTCStr } from '../../../../utils/datetimeHelper';
import apiGetDataReducer, { initialApiGetState } from '../../../../reducers/Common/apiGetDataReducer';
import { apiGBDashboard } from '../../../../apis/apiGBDashboard';
import { CommonActionCreators } from '../../../../actions/Common';
import handleError from '../../../Common/RedirectLogin';
import EventUptimeMonitoringEventDetailDatatable from './EventUptimeMonitoringEventDetailDatatable';
const sf = require('../../../../constants/sfConst');

const EventUptimeMonitoringEventDetail = (props) => {
    const params = props.match.params;
    const viewPermission = authHelper.checkPermission(permissionConst.VIEW_EVENTUPTIMEMONITORING);
    const [dataState, dispatchData] = useReducer(apiGetDataReducer, initialApiGetState);

    const getParams = () => {
        let reqParams = {};
        reqParams.dateFrom = params.dateFrom;
        reqParams.dateTo = params.isProcessDate ? toDateTimeUTCStr(getAdvanceDateByDay(toDateTimeStr(params.dateTo), 1)) : params.dateTo;
        reqParams.sportIDs = params.sportIDs !== '' && params.sportIDs !== 'null' ? params.sportIDs.split(',').map(Number) : [];
        reqParams.eventID = params.eventID !== '' && params.eventID !== 'null' ? parseInt(params.eventID) : null;
        reqParams.operator = params.operator;
        reqParams.wagerTypeID = parseInt(params.wagerTypeID);
        return reqParams;
    };

    const getEventUptimeMonitoringOperatorByEventDetailsListAsync = useCallback(async (page, pageSize) => {
        dispatchData(CommonActionCreators.PRE_GET_LIST());

        try {
            const response = await apiGBDashboard.getEventUptimeMonitoringOperatorByEventDetailsList({
                ...getParams(),
                page: page,
                pageSize: pageSize,
            });

            if (response.result) dispatchData(CommonActionCreators.GET_LIST_SUCCESS(response.data, response.totalCount, page, pageSize));
            else dispatchData(CommonActionCreators.GET_LIST_FAILED(response.message));
        } catch (error) {
            handleError(error);
            dispatchData(CommonActionCreators.GET_LIST_FAILED(error.message));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (
            (params.operator !== null || params.operator !== undefined || params.operator !== '') &&
            (params.dateFrom !== '' || params.dateFrom !== undefined) &&
            (params.dateTo !== '' || params.dateTo !== undefined)
        ) {
            getEventUptimeMonitoringOperatorByEventDetailsListAsync(1, dataState.pageSize);
        }
    }, [params.operator, params.dateFrom, params.dateTo, getEventUptimeMonitoringOperatorByEventDetailsListAsync, dataState.pageSize]);

    const handlePageChange = async (event, page) => {
        await getEventUptimeMonitoringOperatorByEventDetailsListAsync(page + 1, dataState.pageSize);
    };

    const handleRowsPerPageChange = async (event) => {
        await getEventUptimeMonitoringOperatorByEventDetailsListAsync(1, event.target.value);
    };

    return (
        <>
            {!viewPermission ? (
                <div className="row pl-2 pb-1">
                    <h2>{messageConst.FORBIDDEN_ACCESS}</h2>
                </div>
            ) : (
                <div>
                    <div className="top_nav_alert">
                        <div className="collapsible_panel_head">
                            <div className="d-sm-flex align-items-center justify-content-between mb-4">
                                <h1 className="h3 mb-0 text-gray-800 pageheader">{moduleConst.MONITORING_EVENTUPTIME_EVENTDETAILS}</h1>
                            </div>
                        </div>
                        <div className="collapsible_panel_body">
                            <div className="row">
                                <div className="lablename col-sm-6 text-nowrap">
                                    <h6 className="d-inline">
                                        <b>Operator: </b>
                                    </h6>
                                    <span>{params.operator}</span>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="labelname col-sm-6 text-nowrap">
                                    <h6 className="d-inline">
                                        <b>Date: </b>
                                    </h6>
                                    <span>
                                        {toDateTimeStr(params.dateFrom)} - {toDateTimeStr(params.dateTo)}
                                    </span>
                                </div>
                            </div>
                            <div className="row pt-2">
                                <div className="col-sm-12 text-danger">{dataState.errMsg}</div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className={`spinner-border text-primary mr-2 mt-1 ${dataState.isLoading === false ? 'd-none' : ''}`} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                    {dataState.showContent && (
                                        <EventUptimeMonitoringEventDetailDatatable
                                            data={dataState.data}
                                            showPagination={true}
                                            showTable={dataState.showContent}
                                            totalRows={dataState.totalRow}
                                            currentPage={dataState.currentPage - 1}
                                            rowsPerPage={dataState.pageSize}
                                            handlePageChange={handlePageChange}
                                            handleRowsPerPageChange={handleRowsPerPageChange}
                                            module={sf.Module.EventUptimeMonitoringByOperator}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

EventUptimeMonitoringEventDetail.propTypes = {
    match: PropTypes.object.isRequired,
};

export default EventUptimeMonitoringEventDetail;
